/* stylelint-disable */
@import "../variables";


body {
    background-color: #fff;
    font-family: $font;
    color: var(--skin-main-text-color-1);
}

.hero {
    border-bottom-color: var(--skin-background-color-1);
}

.main-menu {
    background-color: $black;
}

.banner-color {
    background-color: var(--skin-banner-background-color-1);
}

.header-banner {
    background-color: $surface-general-secondary;
    color: $black;
}

.header-banner .close-button .close {
    background-color: var(--skin-primary-color-1);
}

h1.header.page-title,
h1.header.page-title::before,
h1.page-title,
h1.page-title::before {
    background-color: $primary;
    color: $white;
}

.product-tile {
    background-color: var(--skin-background-color-1);
    border: 10px solid var(--skin-border-color-1);
}

.refinements ul li button {
    color: var(--skin-primary-color-1);
}

.custom-select {
    background-color: var(--skin-selectbox-background-color-1);
    color: var(--skin-selectbox-text-color-1);
    font-size: 14px;
}

a {
    color: $link-color;

    &:hover {
        color: $link-hover;
    }
}

header ~ #maincontent .container a:not(.btn-primary, .btn-outline-primary) {
    color: $black;

    &:hover {
        color: $link-hover;
    }
}

.price {
    color: var(--skin-price-1);
}

.navbar,
.navbar .nav-item,
.navbar .nav-item a,
.navbar .nav-item.dropdown,
.navbar .nav-item.dropdown a,
.dropdown-menu,
.dropdown-menu a {
    color: $black;
    text-decoration: none;
    text-transform: capitalize;

    @media (min-width: 1200px) {
        color: $white;
        letter-spacing: .04em;
        font-size: 12px;

        &.level-2 {
            font-weight: 700;
        }
    }
}

.navbar .nav-item.dropdown,
.navbar .nav-item.dropdown .dropdown-menu.show {
    color: $black;
}

.navbar .nav-item.dropdown .dropdown-menu.show {
    background-color: $white;

    @media (min-width: 1200px) {
        color: $black;
    }

    a,
    .nav-item {
        color: $black;

        &:hover {
            color: $link-hover;
        }
    }
}
.main-menu {
    .nav-item .nav-link:hover,
    .nav-item .nav-link:focus,
    .nav-item.show .nav-link {
        color: $text-color-1;

        @media (min-width: 1200px) {
            color: $white;
        }
    }
}

.btn {
    transition: all .3s ease-in-out;
    text-decoration: none;
    letter-spacing: 1.5px;
    width: auto;
    height: auto;
    border-radius: 0;
    font-size: 14px;
    font-weight: normal;
    padding: 0;
    margin: 0;
    line-height: 1;
    padding: 16px 12px;

    &:focus,
    &:active:focus {
        box-shadow: none;
    }
}

.btn-primary {
    background-color: $link-color;
    color: $white;
    text-transform: uppercase;
    border: 0;

    &:hover {
        background-color: $border-button-hover;
    }

    &:focus,
    &:active:focus,
    &:not(:disabled):not(.disabled):active:focus {
        box-shadow: none;
    }

    &:disabled,
    &.disabled {
        opacity: 1;
        background: $surface-button-text;
        border: 0;
    }
}

.btn-secondary,
.btn-outline-primary {
    background-color: transparent;
    color: $black;
    border-radius: 0;
    text-decoration: none;
    border: 2px solid $black;
    padding: 14px 32px;

    &:hover {
        color: $border-button-hover;
        border-color: $border-button-hover;
        background-color: transparent;
    }

    &:focus,
    &:active:focus,
    &:not(:disabled):not(.disabled):active:focus {
        box-shadow: none;
    }
}

.btn-block {
    width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font;
    font-weight: bold;
}

h1 {
    font-size: 40px;
}

h2 {
    font-size: 36px;
}

h3 {
    font-size: 32px;
}

h4 {
    font-size: 26px;
}

h5 {
    font-size: 18px;
}

a {
    color: $black;
    text-decoration: underline;

    &:hover {
        color: $link-hover;
    }
}

body,
.card,
.card-header,
.hero {
    background-color: #fff;
    font-family: $font;
    color: $black;
}

.contact-us-topic {
    select.form-control {
        text-transform: none;
    }
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
    max-width: fit-content;
}